import React, { Fragment, useEffect } from "react";
import { Container, Card, Row, Col, Button } from "reactstrap";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import freedomOfThoughtImage from "../assets/images/9-01.png";
import courtImage from "../assets/images/2-01.png";



const FreedomOfThoughtPage = () => {
    useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
        });

    return <Fragment>
    <SEO 
        title="Freedom of Thought"
        description={''}
      />
      <div className="wrapper">
        <div className="main bg-grey">
        <Container>

            <div className="w-100 position-relative">
                {/* Background image */}
                <img src={freedomOfThoughtImage} className="w-100 freedom-thought-image"></img>
                {/* Gradient overlay */}
                <div className="w-100 h-75 freedom-thought-overlay"></div>

                {/* Text */}
                <div className="d-flex flex-row freedom-thought w-100 justify-content-center">
                    {/* Title */}
                    <Row className="w-100 flex-wrap">
                        <Col md="10" className="freedom-thought-title-text">
                            <div className="text-white d-flex flex-column" md="6">
                                <h3 className="text-uppercase m-0 freedom-thought-subtitle">Freedom of Thought</h3>
                                <h1 className="m-0">Psilocybin will be <br/> <b className="highlight">legalized</b> in a court case</h1>
                            </div>
                        </Col>
                        <Col md="2" className="d-flex align-items-end justify-content-center pt-4">
                            <Link to="/freedom-of-thought#contact" className="header-btn outline flex-shrink-0 freedom">Get in Touch</Link>
                        </Col>
                    </Row>
                    
                </div>
            </div>

            <Card className="p-4 text-left freedom-thought-body">
                <Row>
                    <Col>
                        <p className="m-0">
                            FunGuyz spokesperson, Samer Akila, seeks to legalize psilocybin by way of his London, Ontario court case. Lawyers Paul Lewin and Jim Dean will be arguing the case. 
                            Mr. Akila says the psilocybin laws are contrary to freedom of thought under section 2(b) of the Charter of Rights and Freedoms (the “Charter”) and must be struck down. 
                            Freedom of thought is very similar to freedom of consciousness or cognitive liberty. Here in Canada, the Charter calls it freedom of thought.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="m-0">
                            Psilocybin is a freedom of thought tool. 
                            Psilocybin is to freedom of thought what the printing press was to freedom of expression, what a passport is to freedom of mobility or what a church is to freedom of religion. 
                            It is a tool that allows one to experience that freedom in a more fulsome manner. Psilocybin enhances thought in many ways. Psilocybin promotes focus, mindfulness, openness, 
                            connectivity, creativity, cognitive flexibility, interpersonal closeness, gratitude, well-being, peace, calm, compassion, empathy, spiritual experiences, life meaning/ purpose, and ego dissolution. 
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ol className="mb-0">
                            <li>
                                <a href="https://journals.sagepub.com/doi/full/10.1177/0269881117731279">https://journals.sagepub.com/doi/full/10.1177/0269881117731279</a>
                            </li>
                            <li>
                                <a href="https://www.nature.com/articles/s41398-021-01335-5">https://www.nature.com/articles/s41398-021-01335-5</a>
                            </li>
                            <li>
                                <a href="https://journals.sagepub.com/doi/pdf/10.1177/02698811231179801">https://journals.sagepub.com/doi/pdf/10.1177/02698811231179801</a>
                            </li>
                            <li>
                                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3537171/">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3537171/</a>
                            </li>
                            <li>
                                <a href="https://systematicreviewsjournal.biomedcentral.com/articles/10.1186/s13643-022-01930-7">https://systematicreviewsjournal.biomedcentral.com/articles/10.1186/s13643-022-01930-7</a>
                            </li>
                            <li>
                                <a href="https://journals.sagepub.com/doi/full/10.1177/0269881116675513?rfr_dat=cr_pub++0pubmed&url_ver=Z39.88-2003&rfr_id=ori%3Arid%3Acrossref.org">https://journals.sagepub.com/doi/full/10.1177/0269881116675513?rfr_dat=cr_pub++0pubmed&url_ver=Z39.88-2003&rfr_id=ori%3Arid%3Acrossref.org</a>
                            </li>
                            <li>
                                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8575795/">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8575795/</a>
                            </li>
                            <li>
                                <a href="https://www.nature.com/articles/s41598-020-59282-y">https://www.nature.com/articles/s41598-020-59282-y</a>
                            </li>
                            <li>
                                <a href="https://pubmed.ncbi.nlm.nih.gov/30905276/">https://pubmed.ncbi.nlm.nih.gov/30905276/</a>
                            </li>
                            <li>
                                <a href="https://pubmed.ncbi.nlm.nih.gov/26838469/">https://pubmed.ncbi.nlm.nih.gov/26838469/</a>
                            </li>
                            <li>
                                <a href="https://pubmed.ncbi.nlm.nih.gov/37276086/">https://pubmed.ncbi.nlm.nih.gov/37276086/</a>
                            </li>
                            <li>
                                <a href="https://maps.org/images/pdf/books/pahnke/walter_pahnke_drugs_and_mysticism.pdf">https://maps.org/images/pdf/books/pahnke/walter_pahnke_drugs_and_mysticism.pdf</a>
                            </li>
                            <li>
                                <a href="https://pubmed.ncbi.nlm.nih.gov/18562421/">https://pubmed.ncbi.nlm.nih.gov/18562421/</a>
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="m-0">
                            These are great types of thought, but all thought, good or bad, is profoundly important and should not be fettered. 
                            Thought is critical for our democratic, religious, philosophical, spiritual, social, romantic, creative, professional, 
                            and medical decisions.  We are with our thoughts when we are with others and when we are alone. Thought drives our actions, 
                            our successes and our well-being. 
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="m-0">
                            Freedom of thought is not just important in and of itself. Thought is necessary for the proper exercise of almost all other rights 
                            including freedom of religion, freedom of expression, freedom of belief, freedom of opinion, and the right to vote. Some rights 
                            would cease to exist without freedom of thought. Freedom of thought is the most important of all rights.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="m-0">
                            The Supreme Court of Canada has never had a true freedom of thought case. But in <i>R. v. Zundel</i>, [1992] S.C.J. No. 70, a freedom of expression case, 
                            the Supreme Court implied that freedom of thought was the most important constitutional principle. 
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="10" className="mx-auto">
                        <p className="m-0 quote">
                        38      …[I]f there is any principle of the Constitution that more imperatively calls for attachment than any other it is the principle of free thought — 
                        not free thought for those who agree with us but freedom for the thought that we hate. I think that we should adhere to that principle with regard to 
                        admission into, as well as to life within this country.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="m-0">
                            The United Nations General Assembly formally protected freedom of thought by way of the Universal Declaration of Human Rights (the Declaration). 
                            Article 18 declared that 
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="10" className="mx-auto">
                        <p className="m-0 quote">
                            “everyone has the right to freedom of thought, conscience and religion.”
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="m-0">
                            The European Convention on Human Rights at Article 9(1) states, among other things, 
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="10" className="mx-auto">
                        <p className="m-0 quote">
                            “Everyone has the right to freedom of thought, conscience and 
                            religion; this right includes freedom to change his religion or belief and freedom, either alone or in community with others and in public or private, 
                            to manifest his religion or belief, in worship, teaching, practice and observance.”
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="m-0">
                            The 1789 French Declaration of the Rights of Man described freedom of thought as one of the most sacred of rights.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="10" className="mx-auto">
                        <p className="m-0 quote">
                            "Without freedom of thought there can be no such thing as wisdom." - Benjamin Franklin
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="10" className="mx-auto">
                        <p className="m-0 quote">
                            "Carefully guard your thoughts because they are the source of true life." - Proverbs 4:23
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="10" className="mx-auto">
                        <p className="m-0 quote">
                        "Man must be able to think freely and he must be able to express his thoughts freely! He who is against this is not only fascist and primitive 
                        but at the same time is a very great coward also! Only the brave and the honourable men are never afraid of freedom of thought and freedom of 
                        expression of ideas! Just like the cockroaches do not like the light, evil minds also do not like the freedom of thoughts!" - Author Mehmet Murat Idan
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="10" className="mx-auto">
                        <p className="m-0 quote">
                        "From the skin inward is my jurisdiction, is it not? I choose what may or may not cross that border. Here I am the Customs Agent. I am the Coast Guard. 
                        I am the sole legal and spiritual Government of this territory, and only the laws I choose to enact within myself are applicable!!! Now, were I to be 
                        guilty of invading or sabotaging that same territory in others, then the external law of the Nation has every right—indeed, the responsibility—to prosecute me in the agreed-upon manner. 
                        But what I think? Where I focus my awareness? What biochemical reactions I choose to cause within the territorial boundaries of my own skin are not subject 
                        to the beliefs, morals, laws or preferences of any other person!I am a sovereign state, and I feel that my borders are far more sacred than the politically drawn boundaries of any country."
                        - Chemist and pharmacologist Alexander Shulgin and Author Ann Shulgin 
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="m-0">
                            This case will legalize psilocybin in Canada, but it will not stop there. This will be the first step in the legal unravelling of all the psychedelic prohibitions. 
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 className="mb-1 freedom-thought-contact mx-auto text-primary" id="contact">
                            Get in Touch
                        </h3>
                        <hr></hr>

                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <h4 className="mt-0 freedom-thought-contact-headers">Media</h4>
                        <p className="mb-1">If you are media then please contact:</p>
                        <ul>
                            <li>
                                FunGuyz spokesperson <b>Sam Akila</b> at <a href="tel:647-450-9506">(647) 450-9506</a> 
                            </li>
                            <li>
                                Lawyer <b>Paul Lewin</b> at <a href="mailto:paullewin@bellnet.ca">paullewin@bellnet.ca</a> or 
                                <a href="tel:416-499-7945,100"> (416) 499-7945 ext. 100</a> 
                            </li>
                        </ul>
                        <h4 className="freedom-thought-contact-headers">Financial Support</h4>
                        <p className="m-1">If you are interested in supporting the case financially, but want to know more then please contact: </p>
                        <ul>
                            <li>
                                <b>Stephanie Gio</b> at <a href="mailto:connect@larubie.com">connect@larubie.com</a>
                            </li>
                        </ul>
                        <h4 className="freedom-thought-contact-headers">Witness</h4>
                        <p className="m-1">If you are interested in acting as a witness on the case then please contact: </p>
                        <ul>
                            <li>
                                <b>Joanna Shaw</b> at Lewin and Sagara LLP at <br/> <a href="tel:416-499-7945,102">(416) 499-7945 ext. 102</a> or email at <a href="mailto:lewinandsagara@gmail.com.">lewinandsagara@gmail.com</a>
                            </li>
                        </ul>
                    </Col>
                    <Col md="6">
                        <img src={courtImage} className="w-100 h-auto"></img>
                    </Col>
                </Row>
            </Card>
          </Container>
        </div>
      </div>
    </Fragment>;
};

export default FreedomOfThoughtPage;